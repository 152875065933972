<template>
  <AdminBulkCheckInOutForm
    title="Check-Out"
    description="Select reservations on table below to perform check-outs with no modifications required for the reservation. If further changes are needed, then check-out that reservation via the reservation detail facility. To check-out multiple reservations at once use the checkboxes with the bulk check-in button."
    individualAction="CHECK OUT"
    bulkAction="BULK CHECK OUT"
    path="reservation-check-out"
    sortBy="departureDate"
    :parentLoadingSearch="loadingSearch"
    :parentReservations="reservations"
    @search="search"
    @bulkAction="bulkCheckOut"
  />
</template>

<script>
import AdminBulkCheckInOutForm from "@/components/admin/AdminBulkCheckInOutForm.vue";
import AdminSpotReservationService from "@/services/admin/AdminSpotReservationService.js";
import moment from "moment";
export default {
  name: "AdminBulkCheckOut",
  title: "Admin - Bulk Check-Out",
  props: {
    locationId: String
  },
  components: {
    AdminBulkCheckInOutForm
  },
  data() {
    return {
      loadingSearch: false,
      reservations: []
    };
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  methods: {
    async search(searchCriteria) {
      this.loadingSearch = true;
      const service = new AdminSpotReservationService(this.tenantId);
      await service
        .searchCheckOut(this.locationId, searchCriteria)
        .then(response => {
          if (response.statusCode === "Success") {
            this.reservations = response.data;
          } else {
            window.scrollTo(0, 0);
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong",
              layer: "admin"
            });
          }
        })
        .finally(() => {
          this.loadingSearch = false;
        });
    },
    async bulkCheckOut(reservationIds) {
      this.$store.commit("auth/setLoading", true);
      const service = new AdminSpotReservationService(this.tenantId);
      await service
        .bulkCheckOut({
          checkedInOutDate: moment().format("L"),
          reservationIds: reservationIds
        })
        .then(response => {
          if (response.statusCode === "Success" && response.data === true) {
            window.scrollTo(0, 0);
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-success",
              message: "Reservations checked-out successfully",
              layer: "admin"
            });
            this.search({});
          } else {
            window.scrollTo(0, 0);
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message:
                "Some or all of your reservations may not have been checked out. Please try again.",
              layer: "admin"
            });
          }
        })
        .finally(() => {
          this.$store.commit("auth/setLoading", false);
        });
    }
  },
  created() {
    this.search({});
  }
};
</script>
